/* eslint-disable no-unsafe-optional-chaining, import/no-named-as-default-member, import/no-named-as-default */
/* eslint-disable no-unused-vars, @next/next/no-html-link-for-pages */

import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { Cookie } from 'next-cookie';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import useDebounce from '../../hooks/useDebounce';
import { useMoengageEventTracker } from '../../hooks/useMoengage';
import useSourceScreen from '../../hooks/useSourceScreen';

import { searchCourses } from '../../redux/slices/homeSlice';

import { phpApi } from '../../utils/AxiosInstance';
import { getAllUTMParams } from '../../utils/helper';

import styles from '../../styles/Header.module.scss';

const Notifications = dynamic(() => import('./Notifications'));
const Categories = dynamic(() => import('./Categories'));
const Sidebar = dynamic(() => import('./Sidebar'));
const UserMenuComponent = dynamic(() => import('./UserMenu'));

const StoreIcon = dynamic(() => import('../../assets/images/icon/store.svg'));
const StiqOfflineIcon = dynamic(() => import('../../assets/images/icon/stiq-offline.svg'));
const HamburgerIcon = dynamic(() => import('../../images/hamburger.svg'));

const cookies = new Cookie();

function Header({ isSticky, isStoreEnableFlag }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const isHome = React.useMemo(() => router.asPath === '/', [router?.asPath]);

  const { categories, searchResults, isSearched, getUPSCDetails } = useSelector((state) => state.home);
  const { isAuthenticated, authUser } = useSelector((state) => state.auth);

  const authUserEmail = authUser?.email;

  const isStoreEnabled = React.useMemo(() => !!(
    getUPSCDetails?.store_enabled ||
    authUser?.storeEnable ||
    isStoreEnableFlag
  ), [authUser, getUPSCDetails]);

  const trackMoengageEvent = useMoengageEventTracker();
  const screen = useSourceScreen();

  const [isShowCategories, setIsShowCategories] = useState(false);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [showExploreModal, setShowExploreModal] = useState(false);
  const [goTrackierUrl, setGoTrackierUrl] = useState(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length > 0) {
      dispatch(searchCourses(debouncedSearchTerm));
    }
  }, [dispatch, debouncedSearchTerm]);

  useEffect(() => {
    if (searchTerm?.trim()?.length > 0) {
      if (isSearched) {
        setIsShowSearch(true);
      }
    } else {
      setIsShowSearch(false);
    }
  }, [isSearched, searchTerm]);

  useEffect(() => {
    if (isShowCategories) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isShowCategories]);

  const onBlur = () => {
    setIsShowCategories(false);
    setShowExploreModal(!showExploreModal);
  };

  const onHamburgerMenu = () => {
    setIsShowSidebar(!isShowSidebar);
  };

  const onSearchHandler = (e) => {
    if (searchTerm.length >= 3) {
      try {
        trackMoengageEvent('search_results', {
          search_results: e.target.value,
        });
      } catch (error) {
        console.log(error);
      }
    }
    setSearchTerm(e.target.value);
  };

  const onCourseSelection = (course) => {
    const userID = cookies.get('userID');
    const mobNo = cookies.get('mobileNumber');
    (async () => {
      const response = await phpApi.get(`/courseview/${course?.course_slug}`);
      if (response?.data?.success) {
        const courseDetails = response?.data?.data;
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          Adjust?.trackEvent({
            eventToken: 'fc8e05',
            callbackParams: [
              {
                key: 'user_id',
                value: userID ? userID?.toString() : '',
              },
              {
                key: 'email_id',
                value: authUserEmail || '',
              },
              {
                key: 'mobile_number',
                value: mobNo ? mobNo?.toString() : '',
              },
              {
                key: 'productId',
                value: courseDetails?.course_detail?.course_id
                  ? courseDetails?.course_detail?.course_id?.toString()
                  : '',
              },
              {
                key: 'categoryId',
                value: courseDetails?.course_detail?.course_type_id
                  ? courseDetails?.course_detail?.course_type_id?.toString()
                  : '',
              },
              {
                key: 'price',
                value:
                  courseDetails?.course_detail?.package_discount_price_2 > 0
                    ? courseDetails?.course_detail?.package_discount_price_2?.toString()
                    : 'free',
              },
            ],
          });
        });
      }
    })();
    setIsShowSearch(false);
    const redirectLink = `/course-detail/${course?.course_slug}`;
    router.push(redirectLink);
  };

  const handleMoengage = () => {
    sessionStorage.removeItem('scrollToTryNew');
    try {
      trackMoengageEvent('logo_clicked', {
        source_screen: screen,
        position: 'header',
      });
    } catch (error) {
      console.log(error);
    }
  };
  const searchClicked = () => {
    try {
      trackMoengageEvent('search_clicked', {
        source_screen: screen,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const blurFunction = () => {
    setTimeout(() => {
      setIsShowSearch(false);
    }, 300);
  };
  const focusFunction = () => {
    if (searchTerm && searchTerm.length > 0) {
      setIsShowSearch(true);
    } else {
      setIsShowSearch(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && authUser !== null && typeof window !== 'undefined') {
      const userId = cookies.get('userID');
      const trackierUrl = new URL('https://studyiq.gotrackier.com/pixel');

      trackierUrl.searchParams.append('av', '63e351c8d9ff1718a75f6290');
      trackierUrl.searchParams.append('user_id', authUser?.id || userId);
      trackierUrl.searchParams.append('name', authUser?.firstName);
      trackierUrl.searchParams.append('phone', authUser?.mobileNumber);
      trackierUrl.searchParams.append('email', authUser?.email);
      trackierUrl.searchParams.append('goal_value', 'Registration');
      trackierUrl.searchParams.append('campaignId', getAllUTMParams()?.campaignId);
      trackierUrl.searchParams.append('gclid', getAllUTMParams()?.gclid);
      trackierUrl.searchParams.append('sourceCampaignName', getAllUTMParams()?.sourceCampaignName);
      trackierUrl.searchParams.append('utmCampaign', getAllUTMParams()?.utmCampaign);
      trackierUrl.searchParams.append('utmContent', getAllUTMParams()?.utmContent);
      trackierUrl.searchParams.append('utmMedium', getAllUTMParams()?.utmMedium);
      trackierUrl.searchParams.append('utmSource', getAllUTMParams()?.utmSource);
      trackierUrl.searchParams.append('utmTerm', getAllUTMParams()?.utmTerm);

      setGoTrackierUrl(trackierUrl.toString());
    }
  }, [authUser, isAuthenticated, authUser?.id, authUser?.email, authUser?.firstName, authUser?.mobileNumber]);
  useEffect(() => {
    try {
      if (router?.pathname === '/' && sessionStorage?.getItem('courseId') && sessionStorage?.getItem('pdpTimeSpent')) {
        trackMoengageEvent('pdp_engagement', {
          course_id: parseInt(sessionStorage?.getItem('courseId'), 10) || '',
          pdp_time_spent: Math.round((Date.now() - sessionStorage?.getItem('pdpTimeSpent')) / 1000) || 0,
          screen_name: 'home',
        });
        sessionStorage.removeItem('courseId');
        sessionStorage.removeItem('pdpTimeSpent');
      }
    } catch (error) {
      // empty
    }
  }, []);

  return (
    <>
      <iframe src={goTrackierUrl} title="trackier-layer" width="1" height="1" />
      <header id="headerWrapper" className={isSticky ? styles.headerWrapperSticky : styles.headerWrapper}>
        <div
          className={classNames(styles.header_wrapper, {
            [styles.isHome]: isHome,
            [styles.isStoreEnabled]: isStoreEnabled,
          })}
          onClick={onBlur}
        >
          <div className={styles.logo_wrapper} onClick={handleMoengage}>
            <Link href="/" aria-label="Study.IQ Homepage">
              <Image src="/images/studyiq.png" alt="Study.IQ Logo" height={60} width={60} />
            </Link>
          </div>
          <label htmlFor="site-search-input" className={styles.search_wrapper} onClick={() => searchClicked()}>
            <FontAwesomeIcon icon={faSearch} fontSize={14} color="#423dcf" />
            <input
              id="site-search-input"
              placeholder="Find video courses, mock tests, live classes..."
              onChange={onSearchHandler}
              onFocusCapture={() => focusFunction()}
              onBlurCapture={() => blurFunction()}
            />
            {isShowSearch && (
              <div className={styles.searchresults_wrapper}>
                {searchResults?.length > 0 ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {searchResults?.map((course, index) => (
                      <div
                        className={styles.search_result}
                        onClick={() => onCourseSelection(course)}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                      >
                        {course?.course_title}
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={styles.no_courses_found}>{isSearched && 'No courses found'}</div>
                )}
              </div>
            )}
          </label>

          <div style={{ display: 'flex', marginLeft: 'auto', gap: '12px' }}>
            {isAuthenticated && <Notifications />}

            {/* <Link legacyBehavior href="https://www.studyiq.com/lp/teacher-excellence-program.html">
              <a
                className={styles.facultyEnhancement}
                onClick={() => trackMoengageEvent('fep_clicked_header')}
                target="_blank"
              >
                <FacultyExcellenceProgram />
              </a>
            </Link> */}

            {isHome && isStoreEnabled && (
              <Link href="/goal" className={styles.exploreStore}>
                <StoreIcon />
                <span>Store</span>
              </Link>
            )}

            {isHome && isStoreEnabled && (
              <Link href="/offline-courses">
                <a className={styles.exploreOfflineStore}>
                  <StiqOfflineIcon />
                  <span>SIQ Offline</span>
                </a>
              </Link>
            )}
          </div>

          {isAuthenticated && (
            <div className={styles.hmenu}>
              <button type="button" className={styles.hamburgerIconBtn} onClick={onHamburgerMenu}>
                <HamburgerIcon />
              </button>
            </div>
          )}

          <UserMenuComponent />
        </div>
        <div className={styles.categories_wrapper}>
          <Categories isShowCategories={isShowCategories} categories={categories} onBlur={onBlur} />
        </div>
      </header>
      {isAuthenticated && <Sidebar isShowSidebar={isShowSidebar} hideSideMenu={() => setIsShowSidebar(false)} />}
    </>
  );
}

export default Header;
